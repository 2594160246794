import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "./VideoGallerySection.css";
export default function VideoGallerySection() {
  return (
    <div className="container-fluid mb-4">
      <div className="row">
        <div className="col-12 text-center mb-2">
          <div className="main-heading">
            <h2>Video Showcase</h2>
            <p>Watch Our Event in Action</p>
          </div>
        </div>
        <div className="col-12 mb-2">
          <Swiper
            autoplay={{
              delay: 3000,
              disableOnInteraction: true,
              pauseOnMouseEnter: true,
            }}
            pagination={false}
            loop={true}
            draggable={true}
            modules={[Autoplay, Pagination]}
            className="mySwiper video-gallery shorts-videos"
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
            }}>
            <SwiperSlide>
              <iframe
                height="599"
                src="https://www.youtube.com/embed/FtWVdlOFTDA"
                title="NRI Nivesh India Property Expo - Hubtown"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                height="599"
                src="https://www.youtube.com/embed/BCk9sh4VAiw"
                title="NRI Nivesh India Property Expo - Ashwin Sheth Group"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                height="599"
                src="https://www.youtube.com/embed/-XEeIji_jWo"
                title="NRI Nivesh India Property Expo - Runwal Group"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                height="599"
                src="https://www.youtube.com/embed/RN7NPTOaXwc"
                title="NRI Nivesh India Property Expo - L&amp;T Realty"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                height="599"
                src="https://www.youtube.com/embed/V_WsPLcj3bs"
                title="NRI Nivesh India Propertry Expo - Mahindra Lifespaces"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                height="599"
                src="https://www.youtube.com/embed/kdhj1lWVKYA"
                title="NRI Nivesh India Property Expo - Rameshwar Group"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen></iframe>
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="col-12">
          <Swiper
            autoplay={{
              delay: 3000,
              disableOnInteraction: true,
              pauseOnMouseEnter: true,
            }}
            pagination={false}
            loop={true}
            draggable={true}
            modules={[Autoplay, Pagination]}
            className="mySwiper video-gallery"
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 20,
              },
            }}>
            <SwiperSlide>
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/IqHf04yG_Hc"
                title="NRI Nivesh India Property Expo - BPTP"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/1jQnGlMgBSs"
                title="ABU DHABI June 23-24, 2024"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/XI2e_qVncmw"
                title="Singapore Video 16 17 March"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/qc7w4k4kBSM"
                title="Abu Dhabi Jan 20 21- 2024 Event Video"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/NxQmxfsFtlg"
                title="Nairobi Property Expo 2022"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/eVUoBMSfW1E"
                title="Singapore Property Expo 2022"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/pZBE1A8R3v0"
                title="NRI Nivesh India Property Expo - Singapore March 2024"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/b9_CqcLir80"
                title="NRI Nivesh India Property Expo - Jan 2024"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/i2Z68VK19Y8"
                title="NRI Nivesh India Property &amp; Investment Expo - Dec 2023."
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/zWx-S5aeHWU"
                title="NRI Nivesh India Property and Investment Expo - Abu Dhabi 2023"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/XkokwhmgDng"
                title="NRI Nivesh India Property &amp; Investments Expo Nairobi 2023"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/LoZqXOv_jU4"
                title="NRI Nivesh India Property &amp; Investments Expo Abu Dhabi 2023"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/z-5VbkMVkJA"
                title="NRI Nivesh - Our Services"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/rd9A4rZSBAg"
                title="NRI Nivesh India Property &amp; Investment Expo Singapore 2022"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen></iframe>
            </SwiperSlide>
            <SwiperSlide>
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/Ejd5ndAva-8"
                title="NRI Nivesh Property &amp; Investment Expo Nairobi 2022 | #financialplanning #financialfreedom #taxation"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen></iframe>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
}
