import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "./TopHeader.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";


// import required modules
import ClientLogo from "../../../../assest/img/nriniveshpropertyexpo-logo.png";
import PostApiCall from "../../Api/PostApi";
export const TopHeader = () => {
    const [events, setEvents] = useState([]);
    const [TotalProducts, setTotalProducts] = useState([]);
    const [TotalQuotations, setTotalQuotations] = useState([]);
    const [TotalOrders, setTotalOrders] = useState([]);
    const [TotalDispatchedOrders, setTotalDispatchedOrders] = useState([]);
    const [TotalSalesLeads, setTotalSalesLeads] = useState([]);
    const [TotalUsers, setTotalUsers] = useState([]);

    useEffect(() => {
        PostApiCall.postRequest(
            {
                whereClause: "",
            },
            "GetEvents"
        ).then((results) =>
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    setEvents(obj.data);
                }
            })
        );
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }, []);


    return (
        <>
            <div className="margin-left">
                <div className="container-fluid pt-5">
                    <div className="row pt-4">
                        <div className="col-lg-9 mt-4 ps-lg-0">
                            <div className="top-header pb-0">
                                <Swiper
                                    slidesPerView={1}
                                    spaceBetween={10}
                                    loop={true}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    breakpoints={{
                                        640: {
                                            slidesPerView: 1,
                                            spaceBetween: 20,
                                        },
                                        768: {
                                            slidesPerView: 3,
                                            spaceBetween: 40,
                                        },
                                        1024: {
                                            slidesPerView: 3,
                                            spaceBetween: 10,
                                        },
                                    }}
                                    modules={[Pagination]}
                                    className="mySwiper top-header-swiper"
                                >
                                    {events?.map((data) => {
                                        return (
                                            <SwiperSlide>
                                                <div className="top-header-card">
                                                    <div className="top-header-icon">
                                                        <img src={data.fld_image} className="img-fluid" />
                                                        {/* <BsPersonLinesFill /> */}
                                                    </div>
                                                    <div>
                                                        <span>{data.fld_location}</span>
                                                        <p>{data.enq_count}</p>
                                                        {/* <p>{Totalcustomers}</p> */}
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        )
                                    })}

                                </Swiper>
                            </div>
                        </div>
                        <div className="col-lg-3 mt-4 pe-lg-0">
                            <div className="top-header client-logo">
                                <img src={ClientLogo} className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};