import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import PostApiCall from "../../Api/PostApi";
export default function LineChart() {
    const [chartData, setChartData] = useState({
        options: {
            chart: {
                height: 280,
                type: "area",
                stacked: !0,
                toolbar: {
                    show: 0,
                },
                zoom: {
                    enabled: !0,
                },
            },
            plotOptions: {
                bar: {
                    horizontal: !0,
                    columnWidth: "10%",
                    endingShape: "rounded",
                },
            },
            dataLabels: {
                enabled: 0,
            },
            xaxis: {
                categories: [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec",
                ],
            },
            legend: {
                position: "bottom",
                show: 0,
            },
            colors: ["#000", "#181919", "#0000007e",],
            fill: {
                colors: ["#000", "#181919", "#0000007e"],
            },
            stroke: {
                curve: "smooth",
            },
            // responsive: {
            //     breakpoint: undefined,
            //     options: {},
            // }
        },
    });
    const [series, setSeries] = useState([])
    useEffect(() => {
        PostApiCall.postRequest(
            {
                WhereClause: ``
            },
            "DashboardVisitorRegistration"
        ).then((results) =>
            results.json().then((obj2) => {
                if (results.status == 200 || results.status == 201) {
                    const categories = [
                        "Mar",
                        "Apr",
                        "May",
                        "Jun",
                        "Jul",
                        "Aug",
                    ];
                    const seriesData = [];
                    for (let i = 0; i < obj2.data.length; i++) {
                        const location = obj2.data[i].location;
                        const total_registration = obj2.data[i].total_registration;
                        const monthIndex = categories.indexOf(obj2.data[i].month);

                        // If the location is not in seriesData, add it
                        const seriesIndex = seriesData.findIndex(series => series.name === location);
                        if (seriesIndex === -1) {
                            seriesData.push({ name: location, data: new Array(6).fill(0) });
                        }

                        // Update data array with total_registration value
                        if (monthIndex !== -1) {
                            seriesData[i].data[monthIndex] = total_registration;
                        }
                    }
                    setSeries(seriesData)
                    // Notiflix.Loading.Remove();
                }
            }))
    }, [])


    return (
        <ReactApexChart
            options={chartData.options}
            series={series}
            type="area"
            height={320}
        />
    );
}
