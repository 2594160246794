import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import PostApiCall from "../../Api/PostApi";
export default function ColumnChart() {

    const [options, setOptions] = useState({

        chart: {
            type: 'donut',
        },
        colors: ["#2F2F2F", "#666666", "#B3B3B3"],
        fill: {
            colors: ["#2F2F2F", "#666666", "#B3B3B3"],
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]

    });
    const [series, setSeries] = useState([])

    useEffect(() => {
        PostApiCall.postRequest(
            {
                whereClause: ``
            },

            "GetDashBoardStallBooking"
        ).then((results) =>
            // const objs = JSON.parse(result._bodyText)
            results.json().then((obj2) => {
                if (results.status == 200 || results.status == 201) {


                    const categories = []

                    for (var i = 0; i < obj2.data.length; i++) {
                        categories[i] = obj2.data[i].eqnuiry_from
                    }
                    const data = new Array(obj2.data.length).fill(0);


                    for (var i = 0; i < obj2.data.length; i++) {


                        for (var j = 0; j < categories.length; j++) {

                            if (obj2.data[i].eqnuiry_from == categories[j]) {
                                data[i] = obj2.data[i].enquiries

                            }
                            setSeries([
                                { name: "Expense Data", data: data },

                            ])
                        }
                    }
                    setSeries(data)
                    setOptions({
                        chart: {
                            type: 'donut',
                        },
                        labels: categories,
                        series: data,
                        responsive: [{
                            breakpoint: 480,
                            options: {
                                chart: {
                                    width: 200
                                },
                                legend: {
                                    position: 'bottom'
                                }
                            }
                        }]
                    })
                }
            }))
    }, [])


    return (
        <ReactApexChart
            options={options}
            series={series}
            type="donut"
            height={320}
        />
    );

}
