// context store for NRI NIVESH
import { createContext, useState } from "react";

export const store = createContext();

const Provider = ({ children }) => {
    const [scrollToAboutUs, setScrollToAbouUs] = useState(null)
  return (
    <store.Provider
      value={{scrollToAboutUs,setScrollToAbouUs}}
    >
      {children}
    </store.Provider>
  );
};
export default Provider;
