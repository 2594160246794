var urlString = "https://indiapropexpo.com/nrinivesh-api/";
// var urlString = "http://localhost:3026/nrinivesh-api/";
var schema;
schema = "infairs_co_db";
var PostApiCall = {
    postRequest(userData, url) {
        return fetch(urlString + url, { 
            method: "POST",
            headers: {
                Accept: "application/json",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "*",
                "Content-Type": "application/json",
                "x-auth-token": localStorage.getItem("access"),
                schema: schema,
            },
            body: JSON.stringify(userData),
        })
            .then((response) => {
                // //
                return response;
            })
            .catch((error) => {
                //
                return error;
            });
    },
};

export default PostApiCall;
