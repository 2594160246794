import { MDBDataTable } from "mdbreact";
import React from "react";
export const DashBoardTable = ({ data, type }) => {

    return (
        <>
            <div className="main-dashboard">
                <div className="margin-left">
                    <div className="container-fluid">
                        <div className="row mb-4 px-0">
                            <div className="col-12 px-0">
                                <div className="default-card">
                                    <div className="card-heading d-flex justify-content-between align-items-center">
                                        <h4>{type}</h4>
                                    </div>

                                    <MDBDataTable
                                        noBottomColumns={true}
                                        hover
                                        data={data}
                                        entries={10}
                                        entriesOptions={[2, 10, 15]}
                                        className="latest-ouotes-table"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
