
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";
import { Dashboard } from "./Components/AdminPannel/Dashboard/Dashboard";
import LoginPage from "./Components/AdminPannel/Login/LoginPage";
import { VisitorRegistration } from "./Components/AdminPannel/Registration/VisitorRegistration";
import AbuDhabiPage from "./Pages/AbuDhabi";
import Home from "./Pages/Home";
import KenyaPage from "./Pages/Kenya";
import SingaporePage from "./Pages/Singapore";
// import VisitorRegistration from "./Pages/VisitorRegistration";
import { BookingRegistration } from "./Components/AdminPannel/Registration/BookingRegistration";
import BookYourStall from "./Pages/BookYourStall";
import VisitorRegistrationPage from "./Pages/VisitorRegistration";
import { PrivateRoute } from "./PrivateRoute";
import PrivacyPolicyPage from "./Pages/PrivacyPolicyPage";

// TOI Visitor Registration Form
import TOIVisitorRegistrationForm from "./Components/TOIForms/VisitorRegistrationFormTOI";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/singapore" component={SingaporePage} />
        <Route exact path="/jakarta" component={AbuDhabiPage} />
        <Route exact path="/belgium" component={KenyaPage} />
        <Route
          exact
          path="/visitor-registration"
          component={VisitorRegistrationPage}
        />
        <Route exact path="/privacy-policy" component={PrivacyPolicyPage} />
        <Route exact path="/book-your-stall" component={BookYourStall} />
        <Route exact path="/admin" component={LoginPage} />
        <Route exact path="/dashboard">
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        </Route>
        <Route exact path="/visitor-registration-list">
          <PrivateRoute>
            <VisitorRegistration />
          </PrivateRoute>
        </Route>
        <Route exact path="/booking-enquiry">
          <PrivateRoute>
            <BookingRegistration />
          </PrivateRoute>
        </Route>

        {/* TOl Form */}
        <Route
          exact
          path="/visitorregistration"
          component={TOIVisitorRegistrationForm}
        />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
