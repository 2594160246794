import { Link } from "react-router-dom";
import "./EventsSection.css";
import Singapore from "../../assest/img/event-section/singapore.jpg";
import jakarta from "../../assest/img/event-section/jakarta.jpg";
import belgium from "../../assest/img/event-section/belgium.jpg";
export default function EventsSection() {
  return (
    <div className="container-fluid mb-4">
      <div className="row">
        <div className="col-12 text-center mb-2">
          <div className="main-heading">
            <h2>Upcoming Events</h2>
            <p>Join Us for Unmissable Real Estate Exhibitions</p>
          </div>
        </div>
        <div className="col-md-6 col-lg-4 mb-4 mb-lg-0">
          <div className="event-section-card">
            <div>
              <img src={jakarta} className="img-fluid" alt="Abu Dhabi, NRI Nivesh Property Expo, Real estate, India Property Exhibition" />
              <div className="content">
                <h4>
                  <Link to="/jakarta">Jakarta - Indonesia</Link>
                </h4>
                <b>Venue:</b>
                {/* <p>
                  Royal Rose Hotel - UAE, <br />
                  Abu Dhabi
                </p> */}

                <p>Manhattan Hotel, Jakarta <br></br>
                  Jl. Prof Dr. Satrio Kav 19-24, Karet Kuningan, <br></br>
                  Setiabudi Jakarta 12940
                </p>

                <p>
                  <b>Dates:</b> 24 & 25 Aug 2024
                </p>

                <p>
                  <b>Timings:</b> Sat - Sun from 10:00 am to 07:00 pm
                </p>
              </div>
            </div>
            <div className="btns">
              <Link to="/jakarta" className="theme-btn">
                Read More
              </Link>
              <Link to="/visitor-registration" className="theme-btn">
                Visitor Registration
              </Link>
              <Link to="/book-your-stall" className="theme-btn">
                Book Your Stall
              </Link>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-4 mb-4 mb-lg-0">
          <div className="event-section-card">
            <div>
              <img src={belgium} className="img-fluid" alt="Kenya, NRI Nivesh Property Expo, Real estate, India Property Exhibition" />
              <div className="content">
                <h4>
                  <Link to="/belgium">Antwerp - Belgium</Link>
                </h4>
                <b>Venue:</b>
                {/* <p>
                  Oshwal Center - Nairobi, <br />
                  Kenya
                </p> */}

                <p>Hylitt Hotel <br></br>
                  De Keyserlei 28/30, 2018 Antwerpen</p>

                <p>
                  <b>Dates:</b> 21 & 22 Sept 2024
                </p>

                <p>
                  <b>Timings:</b> Sat - Sun from 10:00 am to 07:00 pm
                </p>
              </div>
            </div>
            <div className="btns">
              <Link to="/belgium" className="theme-btn">
                Read More
              </Link>
              <Link to="/visitor-registration" className="theme-btn">
                Visitor Registration
              </Link>
              <Link to="/book-your-stall" className="theme-btn">
                Book Your Stall
              </Link>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-4 mb-4 mb-lg-0">
          <div className="event-section-card">
            <div>
              <img src={Singapore} className="img-fluid" alt="Singapore, NRI Nivesh Property Expo, Real estate, India Property Exhibition" />
              <div className="content">
                <h4>
                  <Link to="/singapore">Singapore</Link>
                </h4>
                <b>Venue:</b>
                {/* <p>
                  Novotel Singapore on Kitchener, <br />
                  Opp City Square Mall, Ferrer Park, MRT
                </p> */}
                <p>Holiday Inn Singapore Orchard City Centre, an IHG Hotel
                  11 Cavenagh Rd</p>

                <p>
                  <b>Dates:</b> 19 & 20 Oct 2024
                </p>

                <p>
                  <b>Timings:</b> Sat - Sun from 10:00 am to 07:00 pm
                </p>
              </div>
            </div>
            <div className="btns">
              <Link to="/singapore" className="theme-btn">
                Read More
              </Link>
              <Link to="/visitor-registration" className="theme-btn">
                Visitor Registration
              </Link>
              <Link to="/book-your-stall" className="theme-btn">
                Book Your Stall
              </Link>
            </div>
          </div>
        </div>


      </div>
    </div>
  );
}
