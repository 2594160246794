import { Image } from "antd";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "./GallerySection.css";
import img1 from "../../assest/img/gallery/1.jpg";
import img2 from "../../assest/img/gallery/2.jpg";
import img3 from "../../assest/img/gallery/3.jpg";
import img4 from "../../assest/img/gallery/4.jpg";
import img5 from "../../assest/img/gallery/5.jpg";
import img6 from "../../assest/img/gallery/6.jpg";
import img7 from "../../assest/img/gallery/7.jpg";
import img8 from "../../assest/img/gallery/8.jpg";
import img9 from "../../assest/img/gallery/9.jpg";
import img10 from "../../assest/img/gallery/10.jpg";
import img11 from "../../assest/img/gallery/11.jpg";
import img12 from "../../assest/img/gallery/12.jpg";
import img13 from "../../assest/img/gallery/13.jpg";
import img14 from "../../assest/img/gallery/14.jpg";
import img15 from "../../assest/img/gallery/15.jpg";
export default function GallerySection() {
  return (
    <div className="container-fluid mb-4">
      <div className="row">
        <div className="col-12 text-center mb-2">
          <div className="main-heading">
            <h2>Event Highlights</h2>
            <p>A Look Back at Our Past Engagements</p>
          </div>
        </div>
        <div className="col-12">
          <Image.PreviewGroup>
            <Swiper
              autoplay={{
                delay: 2500,
                disableOnInteraction: true,
              }}
              pagination={false}
              loop={true}
              draggable={true}
              modules={[Autoplay, Pagination]}
              className="mySwiper"
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 5,
                  spaceBetween: 20,
                },
              }}>
              <SwiperSlide>
                {" "}
                <Image src={img1} className="img-fluid" alt="NRI Nivesh Property Expo, Real estate, India Property Exhibition" />
              </SwiperSlide>
              <SwiperSlide>
                {" "}
                <Image src={img2} className="img-fluid" alt="NRI Nivesh Property Expo, Real estate, India Property Exhibition" />
              </SwiperSlide>
              <SwiperSlide>
                {" "}
                <Image src={img3} className="img-fluid" alt="NRI Nivesh Property Expo, Real estate, India Property Exhibition" />
              </SwiperSlide>
              <SwiperSlide>
                {" "}
                <Image src={img4} className="img-fluid" alt="NRI Nivesh Property Expo, Real estate, India Property Exhibition" />
              </SwiperSlide>
              <SwiperSlide>
                {" "}
                <Image src={img5} className="img-fluid" alt="NRI Nivesh Property Expo, Real estate, India Property Exhibition" />
              </SwiperSlide>
              <SwiperSlide>
                {" "}
                <Image src={img6} className="img-fluid" alt="NRI Nivesh Property Expo, Real estate, India Property Exhibition" />
              </SwiperSlide>
              <SwiperSlide>
                {" "}
                <Image src={img7} className="img-fluid" alt="NRI Nivesh Property Expo, Real estate, India Property Exhibition" />
              </SwiperSlide>
              <SwiperSlide>
                {" "}
                <Image src={img8} className="img-fluid" alt="NRI Nivesh Property Expo, Real estate, India Property Exhibition" />
              </SwiperSlide>
              <SwiperSlide>
                {" "}
                <Image src={img9} className="img-fluid" alt="NRI Nivesh Property Expo, Real estate, India Property Exhibition" />
              </SwiperSlide>
              <SwiperSlide>
                {" "}
                <Image src={img10} className="img-fluid" alt="NRI Nivesh Property Expo, Real estate, India Property Exhibition" />
              </SwiperSlide>
              <SwiperSlide>
                {" "}
                <Image src={img11} className="img-fluid" alt="NRI Nivesh Property Expo, Real estate, India Property Exhibition" />
              </SwiperSlide>
              <SwiperSlide>
                {" "}
                <Image src={img12} className="img-fluid" alt="NRI Nivesh Property Expo, Real estate, India Property Exhibition" />
              </SwiperSlide>
              <SwiperSlide>
                {" "}
                <Image src={img13} className="img-fluid" alt="NRI Nivesh Property Expo, Real estate, India Property Exhibition" />
              </SwiperSlide>
              <SwiperSlide>
                {" "}
                <Image src={img14} className="img-fluid" alt="NRI Nivesh Property Expo, Real estate, India Property Exhibition" />
              </SwiperSlide>
              <SwiperSlide>
                {" "}
                <Image src={img15} className="img-fluid" alt="NRI Nivesh Property Expo, Real estate, India Property Exhibition" />
              </SwiperSlide>
            </Swiper>
          </Image.PreviewGroup>
        </div>
      </div>
    </div>
  );
}
