import React, { useState } from "react";
import { Dropdown } from "react-bootstrap";
import Offcanvas from 'react-bootstrap/Offcanvas';
import profileimg from "../../../assest/img/UserImage.jpeg";
import "../Navbar/Navbar.css";
import { SideNavbar } from "../SideNavbar/SideNavbar";
export const Navbar = () => {
    const [collapsed, setCollapsed] = React.useState(false);
    const [bodyCollapsed, setBodyCollapsed] = React.useState(true);

    if (bodyCollapsed == true) {
        var bodyElement = document.getElementsByTagName("html")[0];
        bodyElement.classList.add("bodycollapse");
    } else {
        var bodyElement = document.getElementsByTagName("html")[0];
        bodyElement.classList.remove("bodycollapse");
    }
    const handleSidebarCollapse = () => {
        setCollapsed(!collapsed);
        setBodyCollapsed(!bodyCollapsed);
    };

    const toggleFullscreen = () => {
        if (
            !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
            !document.webkitFullscreenElement
        ) {
            // current working methods
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(
                    Element.ALLOW_KEYBOARD_INPUT
                );
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    };
    // toggleFullscreen = toggleFullscreen.bind();
    // toggleSearch = toggleSearch.bind();



    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <div className="header ">
                <div className="header-content container-fluid">
                    <nav className="navbar navbar-expand w-100">
                        {/* <div className="header-left">
                            <div className="dashboard_bar me-4"><Link to={"/dashboard"}></Link></div>
                        </div> */}
                        <div className="collapse navbar-collapse justify-content-between">
                            <div className="header-left">
                                <div
                                    className="nav-bar-link me-3"
                                    onClick={() => handleSidebarCollapse()}>
                                    <i className="fa-solid fa-bars"></i>
                                </div>
                            </div>

                        </div>
                        <ul className="navbar-nav header-right w-100 topbar-list">

                            <li >
                                <h5 className="fw-bold text-white mb-0 me-3">
                                    NRI Nivesh Property Expo.
                                </h5>
                            </li>
                            {/* </div> */}
                            <div className="nav-bar-link me-2" onClick={toggleFullscreen}>
                                <i className="fa-solid fa-expand"></i>
                            </div>
                            <Dropdown className="nav-bar-link p-0">
                                <Dropdown.Toggle id="dropdown-basic">
                                    <img src={profileimg} alt="profile" className="profile-img" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <div className="d-flex align-items-center border-bottom pt-1 pb-2 ">
                                        <img
                                            src={profileimg}
                                            alt="profile"
                                            className="profile-img"
                                        />
                                        <span className="ms-4">Name</span>
                                    </div>
                                    <Dropdown.Item className="border-top">Logout</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </ul>
                    </nav>
                </div>
            </div>
            <SideNavbar collapsed={collapsed} />


            <Offcanvas placement="end" show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Settings</Offcanvas.Title>
                </Offcanvas.Header>
                {/* <Offcanvas.Body>
          Some text as placeholder. In real life you can have the elements you
          have chosen. Like, text, images, lists, etc.
        </Offcanvas.Body> */}
            </Offcanvas>
        </>
    );
};
