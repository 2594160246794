import {
  Container,
  Nav,
  NavDropdown,
  Navbar,
  Offcanvas,
} from "react-bootstrap";
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import "./WebsiteNavbar.css";
import Logo from "../../assest/img/nriniveshpropertyexpo-logo.png";
import { useEffect, useState, useContext } from "react";
import { store } from "../../context/store";
import { Link } from "react-router-dom";

export default function WebsiteNavbar() {
  const { scrollToAboutUs, setScrollToAbouUs } = useContext(store);
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    if (window.pageYOffset > 0) {
      setScroll("scroll");
    } else {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 0);
      });
    }
    if (window.pageYOffset > 0) {
      setScroll("scrollSticky");
    } else {
      window.addEventListener("scrollSticky", () => {
        setScroll(window.scrollY > 0);
      });
    }
  }, []);
  return (
    <Navbar expand="lg" className={scroll ? "fixed-top bg-white" : "bg-white"}>
      <Container fluid>
        <Navbar.Brand href="/">
          <img src={Logo} className="img-fluid" alt="NRI Nivesh Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} />
        <Navbar.Offcanvas
          id={`offcanvasNavbar-expand-lg`}
          aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
          placement="end"
          className="website-navbar">
          <Offcanvas.Header closeButton className="pb-0">
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
              <img src={Logo} className="img-fluid w-25" alt="NRI Nivesh Logo" />
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="#about-us">About Us</Nav.Link>
              {/* <Link 
              to="/#about-us"
              onClick = {()=>{}}
              >About Us</Link> */}
              <NavDropdown
                renderMenuOnMount={true}
                title="Events"
                id={`offcanvasNavbarDropdown-expand-lg`}>
                <NavDropdown.Item href="/jakarta"> Jakarta</NavDropdown.Item>
                <NavDropdown.Item href="/belgium"> Belgium</NavDropdown.Item>


                <NavDropdown.Item href="/singapore">Singapore</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="/visitor-registration">
                Visitor Registration
              </Nav.Link>
              <Nav.Link href="/book-your-stall">Book Your Stall</Nav.Link>
              <ul className="nav-sm-links">
                <li>
                  <a
                    href="https://www.facebook.com/nriniveshpropertyexpo"
                    target="_blank">
                    <FaFacebook />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/nriniveshpropertyexpo/?hl=hi"
                    target="_blank">
                    <FaInstagram />
                  </a>
                </li>
              </ul>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
}
