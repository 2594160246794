import React from "react";
import LeftChart from "./LeftChart.js";
import RightChart from "./RightChart";
export const Charts = () => {
    return (
        <>
            <div className="margin-left">
                <div className="container-fluid">
                    <div className="row mb-4">
                        <div className="col-lg-6 ps-lg-0">
                            <div className='default-card'>
                                <h5 className="card-heading">Visitors Registration</h5>
                                <LeftChart />
                            </div>
                        </div>
                        <div className="col-lg-6 pe-lg-0">
                            <div className='default-card'>
                                <h5 className="card-heading">Booking Enquiry</h5>
                                <RightChart />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}