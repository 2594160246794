import { MDBDataTable } from "mdbreact";
import React from "react";
import { CSVLink } from "react-csv";
import { Navbar } from "../Navbar/NavBar";
export const RegistrationTable = ({ data, type }) => {
  return (
    <>
      <div className="main-dashboard">
        <Navbar />
        <div className="margin-left">
          <div className="container-fluid pt-5">
            <div className="row pt-4 mt-4 mb-4 px-0">
              <div className="col-12 px-0">
                <div className="default-card">
                  <div className="card-heading d-flex justify-content-between align-items-center">
                    <h4>{type}</h4>
                  </div>
                  <MDBDataTable
                    noBottomColumns={true}
                    hover
                    data={data}
                    entries={10}
                    entriesOptions={[2, 10, 15]}
                    className="latest-ouotes-table"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
