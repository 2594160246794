import { Helmet } from "react-helmet";
import Footer from "../Components/Footer/Footer";
import WebsiteNavbar from "../Components/WebsiteNavbar/WebsiteNavbar";
import BookYourStallBanner from "../assest/img/stall.png";
import StallBooking from "../Components/Forms/StallBooking";
export default function BookYourStall() {
  return (
    <>
      <Helmet>
        <title>
        Book Your Stall at the NRI Nivesh Property Expo
        </title>
        <meta
          name="description"
          content="Book your stall at NRI Nivesh Property Expo and showcase your property to a global audience. Don't miss this opportunity! Reserve your spot now."
        />
                                <meta name="keywords" content="Book Your Stall, NRI Nivesh Property Expo, Real estate, India Property Exhibition" />

        <meta
          property="og:title"
          content="Book Your Stall at the NRI Nivesh Property Expo"
        />
        <meta
          property="og:description"
          content="Book your stall at NRI Nivesh Property Expo and showcase your property to a global audience. Don't miss this opportunity! Reserve your spot now."
        />
        <meta property="og:url" content="https://indiapropexpo.com/book-your-stall" />
        <link rel="canonical" href="https://indiapropexpo.com/book-your-stall"/>        <meta property="og:image" content="https://indiapropexpo.com/static/media/stall.fb1a9b6771bfce322e66.png
" />
        <meta
          name="twitter:title"
          content="Book Your Stall at the NRI Nivesh Property Expo"
        />
        <meta
          name="twitter:description"
          content="Book your stall at NRI Nivesh Property Expo and showcase your property to a global audience. Don't miss this opportunity! Reserve your spot now.
"
        />
        <meta name="twitter:image" content="https://indiapropexpo.com/static/media/stall.fb1a9b6771bfce322e66.png" />
      </Helmet>
      <WebsiteNavbar />
      <div>
        <img src={BookYourStallBanner} className="img-fluid" alt="Book Your Stall, NRI Nivesh Property Expo, Real estate, India Property Exhibition" />
        {/* <img
          src={VisitorMobileBanner}
          className="img-fluid d-block d-lg-none"
        /> */}
      </div>
      <div className="container-fluid my-4">
        <div className="row">
          <div className="col-lg-6">
            <div className="main-heading main-heading-left">
              <h2>Book Your Stall</h2>
              <p>Be a Part of the India Property Exhibition</p>
            </div>
            <p className="text-justify">
              This is a fantastic opportunity for Real Estate Builders like you
              to exhibit your properties and services to a wide audience
              globally. By reserving a stall at our event, you'll have the
              chance to showcase your projects and connect with potential
              buyers. To secure your space, simply fill out the form below with
              your details. Our dedicated team will carefully review your
              submission and reach out to you with all the necessary
              information.
            </p>
          </div>
          <div className="col-lg-6">
            <StallBooking />
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}
