import { Link } from "react-router-dom/cjs/react-router-dom.min";
import "./AboutUsSection.css";
export default function AboutUsSection() {
  return (
    <div className="container-fluid my-4">
      <div className="row">
        <div className="col-12 text-center">
          <div className="main-heading">
            <h1>Get Incredible Deals on Properties Across India</h1>
            <p>
              Get to Find Residential, Plots, Villas & Commercial Properties
            </p>
          </div>
          <div className="px-lg-5 mx-lg-5">
            <p className="mb-0">
              Discover your dream property at NRI Nivesh Property Expo, where
              NRIs and Resident Indians find their ideal residential and
              commercial spaces. Our exhibition offers a seamless experience for
              those seeking luxury apartments and villas, spacious plots, or
              lucrative commercial properties. Whether you’re looking to invest
              for tomorrow or seeking your dream home today, we offer a
              selection of Real Estate Builders from India that match your taste
              for luxury and sophistication.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
