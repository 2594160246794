import { Helmet } from "react-helmet";
import KenyaBanner from "../assest/img/home-banner/belgium-banner.jpg";
import KenyaMobileBanner from "../assest/img/home-banner/belgium-mobile-banner.jpg";
import Commercial from "../assest/img/singapore-page/commercial.png";
import Deal from "../assest/img/singapore-page/deal.png";
import Developers from "../assest/img/singapore-page/developers.png";
import Major from "../assest/img/singapore-page/major.png";
import Map from "../assest/img/singapore-page/map.png";
import Payment from "../assest/img/singapore-page/money.png";
import Offers from "../assest/img/singapore-page/offers.png";
import Plots from "../assest/img/singapore-page/plots.png";
import Projects from "../assest/img/singapore-page/projects.png";
import Residential from "../assest/img/singapore-page/residential.png";
import Villas from "../assest/img/singapore-page/villas.png";
import Footer from "../Components/Footer/Footer";
import VisitorRegistrationForm from "../Components/Forms/VisitorRegistrationForm";
import WebsiteNavbar from "../Components/WebsiteNavbar/WebsiteNavbar";
import pdf from "../assest/pdf/Upcoming Events Participation Proposal.pdf"
import { FaFilePdf } from "react-icons/fa";

export default function KenyaPage() {
  return (
    <div className="footer-padding">
      <Helmet>
        <title>
          The Gateway to Indian Property: Exhibition in Kenya        </title>
        <meta
          name="description"
          content="Discover the finest properties in India at our exclusive exhibition in Kenya designed for Indian buyers. Join us and find your dream home today!"
        />
        <meta name="keywords" content="Kenya, NRI Nivesh Property Expo, Real estate, India Property Exhibition, Commercial Property, Residential Apartment, Villas and houses, Plots and Lands" />

        <meta
          property="og:title"
          content="The Gateway to Indian Property: Exhibition in Kenya"
        />
        <meta
          property="og:description"
          content="Discover the finest properties in India at our exclusive exhibition in Kenya designed for Indian buyers. Join us and find your dream home today!"
        />
        <meta property="og:url" content="https://indiapropexpo.com/kenya" />
        <link rel="canonical" href="https://indiapropexpo.com/kenya" />        <meta property="og:image" content=" https://indiapropexpo.com/static/media/3.6636f16258cb6eb9b834.png" />
        <meta
          name="twitter:title"
          content="The Gateway to Indian Property: Exhibition in Kenya"
        />
        <meta
          name="twitter:description"
          content="Discover the finest properties in India at our exclusive exhibition in Kenya designed for Indian buyers. Join us and find your dream home today!
"
        />
        <meta name="twitter:image" content=" https://indiapropexpo.com/static/media/3.6636f16258cb6eb9b834.png" />
      </Helmet>
      <WebsiteNavbar />
      <div>
        <img src={KenyaBanner} className="img-fluid d-none d-lg-block" alt="Kenya Banner" />
        <img src={KenyaMobileBanner} className="img-fluid d-block d-lg-none" alt="Kenya Banner" />
      </div>
      <div className="container-fluid mt-4">
        <div className="row">
          <div className="col-12 text-center">
            <div className="main-heading">
              <h1>
                Are you an NRI from Belgium looking to invest in Indian Real
                Estate?
              </h1>
              <p>Get Incredible Deals on Properties Across India</p>
            </div>
            {/* <p className="text-justify">
              The Indian population in Kenya is over 250,000 and is mostly
              concentrated in urban areas like Nairobi and Mombasa. The
              community is well established in trade, industry and other
              professions. Many Indian professionals also work with MNCs and
              Indian companies in Kenya.
            </p>
            <p className="text-justify">
              Several Kenyans of Indian origin are distinguished lawyers,
              judges, doctors and academicians. Economically, Indians are the
              largest employers in the private sector. The Indian-Kenyans own
              many key businesses in Kenya. These include a wide range of areas
              covering all sectors: manufacturing, agriculture & food processing
              including fisheries, transportation and infrastructure development
              as well as banking and finance.
            </p>
            <p className="text-justify">
              Indian Diaspora-owned firms also figure prominently in the Kenyan
              hotel and tourism sectors. Nairobi boasts of six Swami Narayan
              temples and the Ramgarhia Sikh community of Kenya runs several
              gurudwaras. Indian religious groups, such as the Hindu Council of
              Kenya, are active in social development projects too.
            </p>
            <p className="text-justify">
              There are two Asian FM radio channels broadcasting from Nairobi
              and other cities as well as one weekly newspaper
            </p> */}
          </div>
        </div>
      </div>
      <div className="container-fluid mb-4">
        <div className="row">
          <div className="col-lg-8 text-justify">
            <b>Indian Diaspora in Belgium:</b>
            <p>The Indian community in Belgium, numbering around 80,000 to 100,000, is a vibrant and diverse group concentrated in cities like Brussels, Antwerp, and Leuven. This diaspora includes professionals in IT, finance, academia, and healthcare, as well as entrepreneurs. The Indian community in Belgium actively maintains its cultural heritage, celebrating festivals such as Diwali and Holi, and plays a vital role in the multicultural fabric of Belgian society . In Antwerp, Indian diamond traders, predominantly from the Gujarati, Marwari, Jain communities, have established a significant presence. Known as the diamond capital of the world, Antwerp has been profoundly shaped by these traders, who are integral to the city's diamond trade.Brussels is home to a growing community of Indian IT professionals who are key players in the city's dynamic tech industry. Employed by multinational corporations, tech startups, and European institutions, these professionals bring expertise in software development, data analysis, cybersecurity, and digital transformation.</p>
            <a href={pdf} className="theme-btn" target="_blank">Download <FaFilePdf />
            </a>
            {/* <p className="mb-0">
              Our exhibitions cater to NRIs like you, providing best property
              deals in luxurious villas, commercial spaces, residential
              properties and plots, we have something for everyone. Explore our
              selection of properties and take advantage of exclusive deals
              designed with NRIs in mind. Whether you’re looking for a lucrative
              investment opportunity or a dream home in India, we’re here to
              help you every step of the way. Let us be your trusted partner in
              supporting the Indian real estate from Kenya.
            </p>
            <p>
              Explore an extensive lineup of top real estate developers at our
              exhibition, representing major cities across India. With a wide
              array of projects on display, you’ll find the perfect property to
              suit your needs. From luxurious residential complexes to lucrative
              commercial spaces, our exhibitors offer the best deals for
              discerning investors. Visit NRI Nivesh India Property Expo and
              discover the ideal investment opportunity for you!
            </p> */}
            <div className="events-projects-img d-flex mt-3 justify-content-between">
              <img src={Developers} className="img-fluid" alt="Developers, NRI Nivesh Property Expo, Real estate, India Property Exhibition, Kenya" />
              <img src={Major} className="img-fluid" alt="Major cities, NRI Nivesh Property Expo, Real estate, India Property Exhibition, Kenya" />
              <img src={Projects} className="img-fluid" alt="Projects, NRI Nivesh Property Expo, Real estate, India Property Exhibition, Kenya" />
            </div>
          </div>
          <div className="col-lg-4 events-projects-right-img">
            <div className="row">
              <div className="col-md-6 mb-3 pe-lg-2">
                <img src={Residential} className="img-fluid" alt="Residential Apartments, NRI Nivesh Property Expo, Real estate, India Property Exhibition, Kenya" />
              </div>
              <div className="col-md-6 mb-3 ps-lg-2">
                <img src={Villas} className="img-fluid" alt="Villas and Houses, NRI Nivesh Property Expo, Real estate, India Property Exhibition, Kenya" />
              </div>
              <div className="col-md-6 mb-3 mb-lg-0 pe-lg-2">
                <img src={Plots} className="img-fluid" alt="Plots and Lands, NRI Nivesh Property Expo, Real estate, India Property Exhibition, Kenya" />
              </div>
              <div className="col-md-6 mb-3 mb-lg-0 ps-lg-2">
                <img src={Commercial} className="img-fluid" alt="Commercial Property, NRI Nivesh Property Expo, Real estate, India Property Exhibition, Kenya" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="singapore-parallax-section kenya-parallax-section py-5 mb-4">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-12 text-white text-center">
              <h2>Exclusive Exhibition for Indians in Belgium</h2>
              <h3>
                Meet the Indian Property Developers 1 on 1 directly right hear
                in Belgium
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid mb-4">
        <div className="row">
          <div className="col-lg-6">
            <div className="main-heading main-heading-left">
              <h2>From Rs 30 Lacs to Rs. 15 Crores</h2>
              <p>Get Incredible Deals From Following Cities</p>
            </div>

            <img src={Map} className="img-fluid mt-4" alt="map" id="visitor-registration" />
          </div>
          <div className="col-lg-6">
            <VisitorRegistrationForm />
          </div>
        </div>
      </div>
      <div className="container-fluid mb-4">
        <div className="row align-items-center">
          <div className="col-md-6 col-lg-3 text-center">
            <div>
              <h3 className="mb-0">2 Days Of Incredible Deals</h3>
              <p>on properties from all across India</p>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="deals-card">
              <img src={Deal} className="img-fluid" alt="Deal" />
              <h4>Unbeatable Deals</h4>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="deals-card">
              <img src={Payment} className="img-fluid" alt="Payment" />
              <h4>Flexible Payment Plans</h4>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="deals-card">
              <img src={Offers} className="img-fluid" alt="Offers" />
              <h4>Incredible Pre-Launch Offers</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed-bottom d-block d-md-none p-2 bg-white">
        <a href="#visitor-registration" className="theme-btn text-center w-100">
          Visitor Registration
        </a>
      </div>
      <Footer />
    </div>
  );
}
