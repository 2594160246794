import { Link } from "react-router-dom";
import "./AboutUsLogoSection.css";
import MudraLogo from "../../assest/img/mudra-logo.png";
import IntroductionsLogo from "../../assest/img/Introductions-logo.png";
import Kenya from "../../assest/img/event-section/Kenya.png";
import { useContext, useEffect } from "react";
import { store } from "../../context/store";

export default function AboutUsLogoSection() {
  const { scrollToAboutUs } = useContext(store);
  useEffect(() => {
    if (scrollToAboutUs) {
      const targetDiv = document.getElementById("abous-us");
      const rect = targetDiv.getBoundingClientRect();
      const targetScrollTop = rect.top + window.pageYOffset - 100;
      window.scrollTo({
        top: targetScrollTop,
        behavior: "smooth",
      });
    }
  }, [scrollToAboutUs]);
  return (
    <div className="container-fluid mb-4" id="about-us">
      <div className="row">
        <div className="col-12 text-center mb-2">
          <div className="main-heading">
            <h2>About Us</h2>
            <p>NRI Nivesh: A Fusion of Two Companies</p>
          </div>
        </div>
        <div className="col-md-6 mb-4 mb-lg-0">
          <div className="event-section-card about-us-logo">
            <div>
              <div className="text-center border-bottom">
                <img src={IntroductionsLogo} className="img-fluid " alt="Introductions Logo" />
              </div>
              <div className="content">
                <p>
                  Introductions Trade Shows Group, an award-winning company,
                  specializes in event production, event management, and
                  organizing mega B2B trade fairs in domestic and international
                  markets for the past 25 years. We have a presence in Asian,
                  European, and African markets.
                </p>
                <p>
                  For the last 14 years, we’ve been hosting property fairs in
                  Thailand, Hong Kong, Singapore, Belgium, Kenya, Indonesia, and
                  the UK. Our offices are located in Mumbai, Bengaluru, Delhi,
                  Hyderabad, and Dubai.
                </p>
                <p>
                  We’ve worked closely with CREDAI MCHI Thane and partnered with
                  the Times of India Group for International and National Real
                  Estate Exhibitions and Events for 14 years across 12
                  countries.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 mb-4 mb-lg-0">
          <div className="event-section-card about-us-logo">
            <div>
              <div className="text-center border-bottom">
                <img src={MudraLogo} className="img-fluid " alt="Mudra Logo" />
              </div>
              <div className="content">
                <p>
                  Mudra Founded in 2012 with a simple but revolutionary idea of
                  Investing & Managing Multiple Asset Classes for clients.
                </p>
                <p>
                  We are a world class Investment & Financial Services firm,
                  helping 2000+ NRI families across the Globe to save and invest
                  wisely.
                </p>
                <p>
                  Team Mudra consists of Chartered Accountants, ex-bankers,
                  CFAs, CFPs, & MBAs. Great presence of NRI clients across
                  Singapore, Malaysia, Indonesia, UAE, Kuwait, Kenya, USA, UK,
                  Germany, Netherlands
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
