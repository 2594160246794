import { Link } from "react-router-dom";
import WebsiteNavbar from "../Components/WebsiteNavbar/WebsiteNavbar";
import SingaporeBanner from "../assest/img/home-banner/singapore-banner.jpg";
import SingaporeMobileBanner from "../assest/img/home-banner/singapore-mobile-banner.jpg";
import Commercial from "../assest/img/singapore-page/commercial.png";
import Plots from "../assest/img/singapore-page/plots.png";
import Residential from "../assest/img/singapore-page/residential.png";
import Villas from "../assest/img/singapore-page/villas.png";
import Footer from "../Components/Footer/Footer";
import Deal from "../assest/img/singapore-page/deal.png";
import Payment from "../assest/img/singapore-page/money.png";
import Offers from "../assest/img/singapore-page/offers.png";
import Developers from "../assest/img/singapore-page/developers.png";
import Major from "../assest/img/singapore-page/major.png";
import Projects from "../assest/img/singapore-page/projects.png";
import { useState } from "react";
import PostApiCall from "../Components/AdminPannel/Api/PostApi";
import Notiflix from "notiflix";
import VisitorRegistrationForm from "../Components/Forms/VisitorRegistrationForm";
import pdf from "../assest/pdf/Upcoming Events Participation Proposal.pdf";
import { FaFilePdf } from "react-icons/fa";



import Map from "../assest/img/singapore-page/map.png";
import { Helmet } from "react-helmet";
export default function SingaporePage() {
  const [visitorId, setVisitorId] = useState(null)
  const [name, setName] = useState("")
  const [mobile, setMobile] = useState("")
  const [email, setEmail] = useState("")
  const [dayOfVisit, setDayOfVisit] = useState("")
  const [person, setPerson] = useState("")
  const [msg, setMsg] = useState("")
  const [taxService, setTaxService] = useState(false)
  const [indianInvestments, setIndianInvestments] = useState(false)
  const [residential, setResidential] = useState(false)
  const [villa, setVilla] = useState(false)
  const [plots, setPlots] = useState(false)
  const [commercial, setCommercial] = useState(false)
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const numberRegex = /^[0-9]+$/;

  const handleRestForm = () => {
    setName("")
    setMobile("")
    setEmail("")
    setDayOfVisit("")
    setPerson("")
    setMsg("")
    setTaxService(false)
    setIndianInvestments(false)
    setResidential(false)
    setVilla(false)
    setPlots(false)
    setCommercial(false)
  }

  const isEmailValid = (email) => {
    return emailRegex.test(email)
  }

  const isPhoneValid = (phone) => {
    return numberRegex.test(phone)

  }

  const handleValidateForm = () => {

    if (name == "") {
      Notiflix.Notify.failure("Name is required. Please enter your name.")
      return
    }
    if (mobile == "") {
      Notiflix.Notify.failure("Mobile number is required. Please enter your mobile number.")
      return
    } else if (!isPhoneValid(mobile)) {
      Notiflix.Notify.failure("Invalid Mobile Number")
      return
    }
    if (email == "") {
      Notiflix.Notify.failure("Email is required. Please enter your Email.")
      return
    } else if (!isEmailValid(email)) {
      Notiflix.Notify.failure("Invalid email. Please enter a valid email.")
      return
    }
    if (dayOfVisit == "") {
      Notiflix.Notify.failure("Day of visiting is required. Please select a day from the dropdown.")
      return
    }
    if (person == "") {
      Notiflix.Notify.failure("Number of persons visiting is required. Please select from the dropdown.")
      return
    }
    if (msg == "") {
      Notiflix.Notify.failure("Please specify the cities you're interested in.")
      return
    }
    handleSubmitForm()
  }

  const handleSubmitForm = () => {
    Notiflix.Loading.dots("Please Wait")
    // e.preventDefault();
    PostApiCall.postRequest({
      visitorid: visitorId,

      visitorname: name,
      email: email,
      mobile: mobile,
      DayofAttending: dayOfVisit,
      message: msg,
      taxfiling: taxService,
      indiainvestment: indianInvestments,
      residentials: residential,
      plots: plots,
      villa: villa,
      commercial: commercial,
    }, "UpdateVisitorRegistration").then((result) =>
      result.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          Notiflix.Notify.success("Form Submitted Succesfully")
          handleRestForm()
          Notiflix.Loading.remove()
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Something Went Wrong!");
        }
      })
    );
  }

  return (
    <div className="footer-padding">
      <Helmet>
        <title>
          Exclusive Indian Property Expo in Singapore        </title>
        <meta
          name="description"
          content="Find your perfect property in India at our exclusive exhibition in Singapore. Don't miss out on this chance to discover the best real estate options available."
        />
        <meta
          property="og:title"
          content="Exclusive Indian Property Expo in Singapore"
        />
        <meta
          property="og:description"
          content="Find your perfect property in India at our exclusive exhibition in Singapore. Don't miss out on this chance to discover the best real estate options available."
        />
        <meta name="keywords" content="Singapore, NRI Nivesh Property Expo, Real estate, India Property Exhibition, Commercial Property, Residential Apartment, Villas and houses, Plots and Lands" />

        <meta property="og:url" content="https://indiapropexpo.com/singapore" />
        <link rel="canonical" href="https://indiapropexpo.com/singapore" />        <meta property="og:image" content="https://indiapropexpo.com/static/media/1.8a4c5a2c128bd04c3c90.png" />
        <meta
          name="twitter:title"
          content="Exclusive Indian Property Expo in Singapore"
        />
        <meta
          name="twitter:description"
          content="Find your perfect property in India at our exclusive exhibition in Singapore. Don't miss out on this chance to discover the best real estate options available.
"
        />
        <meta name="twitter:image" content="https://indiapropexpo.com/static/media/1.8a4c5a2c128bd04c3c90.png" />
      </Helmet>
      <WebsiteNavbar />
      <div>
        <img src={SingaporeBanner} className="img-fluid d-none d-lg-block" alt="Singapore Banner" />
        <img
          src={SingaporeMobileBanner}
          className="img-fluid d-block d-lg-none"
          alt="Singapore Banner"
        />
      </div>
      <div className="container-fluid mt-4">
        <div className="row">

          <div className="col-12 text-center">
            <div className="main-heading">
              <h1>
                Are you an NRI from Singapore looking to invest in Indian Real
                Estate?
              </h1>
              <p>Get Incredible Deals on Properties Across India</p>
            </div>
            <p className="text-justify">
              Diaspora of Indians in Singapore: According to the Indian Ministry
              of External Affairs ethnic Indians constitute about 9.1 per cent
              or around 3.5 lakhs of the resident population of 3.9 million in
              Singapore. In addition, among the 1.5 million foreigners residing
              in Singapore, about 3.5 lakhs are Indian expatriates holding
              Indian passports, mostly serving in financial services,
              computer/software sectors, students, construction and marine
              sectors. There are about 1.5 lakh Indian migrant workers in
              Singapore. Approximately two-thirds of the Indian community in
              Singapore are of Kannada , Tamil, Andhra origin, Marathi,
              Punjabi's, Malayali's and Sindhi's are the other major Indian
              communities.
            </p>
            <p className="text-justify">
              "Keeping the above facts in mind and looking at the opportunity to
              connect with the Indian expats, who are looking for a genuine
              platform to connect with the premium Indian developers. Indian
              expats have high purchasing power and a preference towards
              investment in India and are connected to their roots with frequent
              family visits and business interests."
            </p>
          </div>
        </div>
      </div>
      <div className="container-fluid mb-4">
        <div className="row">
          <div className="col-lg-8 text-justify">
            <p className="mb-0">
              Our exhibitions cater to NRIs like you, providing best property
              deals in luxurious villas, commercial spaces, residential
              properties and plots, we have something for everyone. Explore our
              selection of properties and take advantage of exclusive deals
              designed with NRIs in mind. Whether you’re looking for a lucrative
              investment opportunity or a dream home in India, we’re here to
              help you every step of the way. Let us be your trusted partner in
              supporting the Indian real estate from Singapore.
            </p>
            <p>
              Explore an extensive lineup of top real estate developers at our
              exhibition, representing major cities across India. With a wide
              array of projects on display, you’ll find the perfect property to
              suit your needs. From luxurious residential complexes to lucrative
              commercial spaces, our exhibitors offer the best deals for
              discerning investors. Visit NRI Nivesh India Property Expo and
              discover the ideal investment opportunity for you!
            </p>
            <a href={pdf} className="theme-btn" target="_blank">Download <FaFilePdf /></a>

            <div className="events-projects-img d-flex mt-3 justify-content-between">
              <img src={Developers} className="img-fluid" alt="Developers, NRI Nivesh Property Expo, Real estate, India Property Exhibition, Singapore" />
              <img src={Major} className="img-fluid" alt="Major cities, NRI Nivesh Property Expo, Real estate, India Property Exhibition, Singapore" />
              <img src={Projects} className="img-fluid" alt="Projects, NRI Nivesh Property Expo, Real estate, India Property Exhibition, Singapore" />
            </div>
          </div>
          <div className="col-lg-4 events-projects-right-img">
            <div className="row">
              <div className="col-md-6 mb-3 pe-lg-2">
                <img src={Residential} className="img-fluid" alt="Residential Apartments, NRI Nivesh Property Expo, Real estate, India Property Exhibition, Singapore" />
              </div>
              <div className="col-md-6 mb-3 ps-lg-2">
                <img src={Villas} className="img-fluid" alt="Villas and Houses, NRI Nivesh Property Expo, Real estate, India Property Exhibition, Singapore" />
              </div>
              <div className="col-md-6 mb-3 mb-lg-0 pe-lg-2">
                <img src={Plots} className="img-fluid" alt="Plots and Lands, NRI Nivesh Property Expo, Real estate, India Property Exhibition, Singapore" />
              </div>
              <div className="col-md-6 mb-3 mb-lg-0 ps-lg-2">
                <img src={Commercial} className="img-fluid" alt="Commercial Property, NRI Nivesh Property Expo, Real estate, India Property Exhibition, Singapore" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="singapore-parallax-section py-5 mb-4">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-12 text-white text-center">
              <h2>Exclusive Exhibition for Indians in Singapore</h2>
              <h3>
                Meet the Indian Property Developers 1 on 1 directly right hear
                in Singapore
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid mb-4">
        <div className="row">
          <div className="col-lg-6">
            <div className="main-heading main-heading-left">
              <h2>From Rs 30 Lacs to Rs. 15 Crores</h2>
              <p>Get Incredible Deals From Following Cities</p>
            </div>

            <img
              src={Map}
              className="img-fluid mb-4 mb-lg-0 mt-lg-4"
              id="visitor-registration"
              alt="Map"
            />
          </div>
          <div className="col-lg-6">
            {/* visitor form  */}
            {/* <div className="border p-3 w-100 visitor-registration-form">
              <div className="main-heading main-heading-left">
                <h2>Kindly Register Below - Free Registration!</h2>
                <p>Snacks & High Tea for all Registered Clients</p>
              </div>
              <div className="row">
                <div className="col-12 mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                  />
                </div>
                <div className="col-lg-6 mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Mobile Number"
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                  />
                </div>
                <div className="col-lg-6 mb-3">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="col-lg-6 mb-3">
                  <select className="form-select">
                    <option selected>Day of Visiting</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
                <div className="col-lg-6 mb-3">
                  <select className="form-select">
                    <option selected>Number of Persons Visiting</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </select>
                </div>
                <div className="col-lg-12 mb-3">
                  <textarea
                    className="form-control"
                    placeholder="Kindly mention cities that you are interested in - Bangalore, Chennai, Delhi NCR, Hyderabad, Mumbai, Pune, Ahmedabad, Dubai, Vadodara, Kochi*"
                    rows={5}></textarea>
                </div>

                <b className="mb-2">Add on Services.</b>
                <div className="col-lg-6">
                  <div className="mb-3">
                    <lable>Please tick if need Assistance for :</lable>
                    <div className="form-check mt-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={taxService}
                        onClick={() => setTaxService(!taxService)}
                        checked={taxService}
                        id="flexCheckDefault"
                      />
                      <label
                        className="form-check-label"
                        for="flexCheckDefault">
                        Tax Filing & Will in India
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={indianInvestments}
                        onClick={() => setIndianInvestments(!indianInvestments)}
                        checked={indianInvestments}
                        id="flexCheckChecked"
                      />
                      <label
                        className="form-check-label"
                        for="flexCheckChecked">
                        Indian Investments
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mb-3 mb-lg-0">
                  <lable>Looking for :</lable>
                  <div className="d-flex gap-3 mt-2">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={residential}
                        onClick={() => setResidential(!residential)}
                        checked={residential}
                        id="flexCheckDefault"
                      />
                      <label
                        className="form-check-label"
                        for="flexCheckDefault">
                        Residential
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={plots}
                        onClick={() => setPlots(!plots)}
                        id="flexCheckChecked"
                      />
                      <label
                        className="form-check-label"
                        for="flexCheckChecked">
                        Plots
                      </label>
                    </div>
                  </div>
                  <div className="d-flex gap-3">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={villa}
                        onClick={() => setVilla(!villa)}
                        checked={villa}
                        id="flexCheckChecked"
                      />
                      <label
                        className="form-check-label"
                        for="flexCheckChecked">
                        Villa
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value={commercial}
                        onClick={() => setCommercial(!commercial)}
                        checked={commercial}
                        id="flexCheckChecked"
                      />
                      <label
                        className="form-check-label"
                        for="flexCheckChecked">
                        Commercial
                      </label>
                    </div>
                  </div>
                </div>
                <div>
                  <button
                    // type="submit"
                    className="theme-btn"
                    onClick={() => handleValidateForm()}
                  >
                    Register as a Visitor
                  </button>
                </div>
              </div>
            </div> */}
            <VisitorRegistrationForm />

          </div>
        </div>
      </div>
      <div className="container-fluid mb-4">
        <div className="row align-items-center">
          <div className="col-md-6 col-lg-3 text-center">
            <div>
              <h3 className="mb-0">2 Days Of Incredible Deals</h3>
              <p>on properties from all across India</p>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="deals-card">
              <img src={Deal} className="img-fluid" alt="Deal" />
              <h4>Unbeatable Deals</h4>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="deals-card">
              <img src={Payment} className="img-fluid" alt="Payment" />
              <h4>Flexible Payment Plans</h4>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="deals-card">
              <img src={Offers} className="img-fluid" alt="Offers" />
              <h4>Incredible Pre-Launch Offers</h4>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed-bottom d-block d-md-none p-2 bg-white">
        <a href="#visitor-registration" className="theme-btn text-center w-100">
          Visitor Registration
        </a>
      </div>
      <Footer />
    </div>
  );
}
