import { Helmet } from "react-helmet";
import AboutUsLogoSection from "../Components/AboutUsLogoSection/AboutUsLogoSection";
import AboutUsSection from "../Components/AboutUsSection/AboutUsSection";
import EventsSection from "../Components/EventsSection/EventsSection";
import Footer from "../Components/Footer/Footer";
import GallerySection from "../Components/GallerySection/GallerySection";
import MainHomeSlider from "../Components/MainHomeSlider/MainHomeSlider";
import ParallaxSection from "../Components/ParallexSection/ParallaxSection";
import VideoGallerySection from "../Components/VideoGallerySection/VideoGallerySection";
import WebsiteNavbar from "../Components/WebsiteNavbar/WebsiteNavbar";
export default function Home() {
  return (
    <div className="footer-padding">
      <Helmet>
        <title>
          NRI Nivesh India Property Expo: Find Your Dream Property        </title>
        <meta
          name="description"
          content="NRI Nivesh India Property Expo offers a diverse selection of properties in India. Start your investment journey with us today!"
        />
        <meta
          property="og:title"
          content="NRI Nivesh India Property Expo: Find Your Dream Property"
        />
        <meta
          property="og:description"
          content="Explore a wide range of properties in India and grab incredible deals. From residential to commercial, find your dream property at the best prices."
        />
        <meta name="keywords" content="Singapore, Abu Dhabi, Kenya, NRI Nivesh Property Expo, Real estate, India Property Exhibition" />
        <meta property="og:url" content="https://indiapropexpo.com/" />
        <link rel="canonical" href="https://indiapropexpo.com/"></link>
        <meta property="og:image" content="https://indiapropexpo.com/static/media/nriniveshpropertyexpo-logo.8fbc1cd7f3eca14090ff.png" />
        <meta
          name="twitter:title"
          content="NRI Nivesh India Property Expo: Find Your Dream Property"
        />
        <meta
          name="twitter:description"
          content="Explore a wide range of properties in India and grab incredible deals. From residential to commercial, find your dream property at the best prices.
"
        />
        <meta name="twitter:image" content="https://indiapropexpo.com/static/media/nriniveshpropertyexpo-logo.8fbc1cd7f3eca14090ff.png" />
      </Helmet>
      <WebsiteNavbar />
      <MainHomeSlider />
      <AboutUsSection />
      <ParallaxSection />
      <AboutUsLogoSection />
      <EventsSection />
      <GallerySection />
      <VideoGallerySection />
      <Footer />
      <div className="fixed-bottom d-block d-md-none p-2 bg-white">
        <a href="/visitor-registration" className="theme-btn text-center w-100">
          Visitor Registration
        </a>
      </div>
    </div>
  );
}
