import "./ParallaxSection.css";
export default function ParallaxSection() {
  return (
    <div className="parallax-section py-5 mb-4">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div className="col-12 text-white text-center">
            <h2>Exclusive Exhibitions for Indians</h2>
            <h3>Meet the Indian Property Developers 1 on 1 directly!</h3>
          </div>
        </div>
      </div>
    </div>
  );
}
