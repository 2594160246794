import moment from "moment";
import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import { TfiReload } from "react-icons/tfi";
import PostApiCall from "../AdminPannel/Api/PostApi";

export default function VisitorRegistrationForm() {
  const [visitorId, setVisitorId] = useState(null);
  const [eventId, setEventId] = useState("");
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [dayOfVisit, setDayOfVisit] = useState([]);
  const [events, setEvents] = useState([]);
  const [person, setPerson] = useState("");
  const [msg, setMsg] = useState("");
  const [taxService, setTaxService] = useState(false);
  const [indianInvestments, setIndianInvestments] = useState(false);
  const [residential, setResidential] = useState(false);
  const [villa, setVilla] = useState(false);
  const [plots, setPlots] = useState(false);
  const [commercial, setCommercial] = useState(false);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const numberRegex = /^[0-9]+$/;
  const [showEvents, setShowEvents] = useState(false);
  const [filteredItems, setFilterdItems] = useState([]);
  const [clause, setClause] = useState("");
  const [listedDates, setListedDates] = useState([]);
  const [eventName, setEventName] = useState("");
  const [siteName, setSiteName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const personList = [
    { number: 1, text: "One" },
    { number: 2, text: "Two" },
    { number: 3, text: "Three" },
    { number: 4, text: "Four" },
    { number: 5, text: "Five" },
    { number: 6, text: "Six" },
    { number: 7, text: "Seven" },
    { number: 8, text: "Eight" },
    { number: 9, text: "Nine" },
    { number: 10, text: "Ten" },
  ];
  const url = window.location.pathname;

  //  **** For Location Route *****
  const route = window.location.pathname;
  let registrationUrl = "https://indiapropexpo.com/visitor-registration  "; // Default URL for other routes

  if (route === "/singapore") {
    registrationUrl = "https://indiapropexpo.com/singapore";
  } else if (route === "/jakarta") {
    registrationUrl = "https://indiapropexpo.com/jakarta";
  } else if (route === "/belgium") {
    registrationUrl = "https://indiapropexpo.com/belgium";
  }

  useEffect(() => {
    if (url == "/visitor-registration") {
      setShowEvents(true);
    }
    if (url == "/singapore") {
      setEventId(1);
    }
    if (url == "/jakarta") {
      setEventId(2);
    }
    if (url == "/belgium") {
      setEventId(3);
    }
  }, []);

  useEffect(() => {
    getEvents();
  }, []);

  const whereClause = eventId ? `where fld_event_id = '${eventId}` : "";

  const getEvents = () => {
    PostApiCall.postRequest(
      {
        whereClause: "",
      },
      "GetEvents"
    ).then((results) =>
      results.json().then((obj) => {
        if (results.status === 200 || results.status === 201) {
          setEvents(obj.data);
          setFilterdItems(
            obj.data.filter((data) =>
              data.fld_location
                .toLowerCase()
                .includes(
                  window.location.pathname
                    .replace(/\//g, "")
                    .replace(/-/g, " ")
                    .toLowerCase()
                )
            )
          );
          Notiflix.Loading.remove();
        } else {
          Notiflix.Notify.failure("error occurred!");
          Notiflix.Loading.remove();
        }
      })
    );
    // getEventDates()
  };

  useEffect(() => {
    getDates(filteredItems[0]?.fld_Start_Date, filteredItems[0]?.fld_End_date);
  }, [filteredItems]);

  function getDates(startDate, endDate) {
    const dates = [];
    let currentDate = new Date(startDate);
    let EndD = new Date(endDate);
    while (currentDate <= EndD) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    setListedDates(dates);
    return dates;
  }
  const getEventDates = () => {
    if (eventId != null) {
      let filterResults = events.filter((event) => {
        return event.fld_event_id == eventId;
      });

      const filterStartDate = filterResults[0].fld_start_data;
    } else {
    }
  };

  function formatDateRange(startDate, endDate) {
    // Convert the start date string to a Date object
    const start = new Date(startDate);

    // Convert the end date string to a Date object
    const end = new Date(endDate);

    // Get the day, month, and year components for start and end dates
    const startDay = start.getUTCDate();
    const startMonthIndex = start.getUTCMonth();
    const startYear = start.getUTCFullYear();

    const endDay = end.getUTCDate();
    const endMonthIndex = end.getUTCMonth();
    const endYear = end.getUTCFullYear();

    // Array of month names
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Format the start date
    const formattedStartDate = `${startDay}th`;

    // Format the end date
    const formattedEndDate = `${endDay}th`;

    // Format the month and year
    const formattedMonthYear = `${months[startMonthIndex]} ${startYear}`;

    // Format the date range
    const formattedDateRange = `${formattedStartDate} to ${formattedEndDate} ${months[startMonthIndex]} ${startYear}`;

    // Return the formatted date range
    return formattedDateRange;
  }

  // function formatVisitingDate(dateString) {
  //   const date = new Date(dateString);
  //   const options = { month: "long", day: "numeric", year: "numeric" };
  //   return date.toLocaleDateString("en-US", options);
  // }

  const handleRestForm = () => {
    setName("");
    setMobile("");
    setEmail("");
    setDayOfVisit("");
    setPerson("");
    setMsg("");
    setTaxService(false);
    setIndianInvestments(false);
    setResidential(false);
    setVilla(false);
    setPlots(false);
    setCommercial(false);
    setListedDates([])
    setEvents([])
  };

  const handleFormReset = () => {
    window.location.reload()
  }
  const isEmailValid = (email) => {
    return emailRegex.test(email);
  };

  const isPhoneValid = (phone) => {
    return numberRegex.test(phone);
  };

  const handleValidateForm = () => {
    if (name == "") {
      Notiflix.Notify.failure("Name is required. Please enter your name.");
      return;
    }
    if (mobile == "") {
      Notiflix.Notify.failure(
        "Mobile number is required. Please enter your mobile number."
      );
      return;
    } else if (!isPhoneValid(mobile)) {
      Notiflix.Notify.failure("Invalid Mobile Number");
      return;
    }
    if (email == "") {
      Notiflix.Notify.failure("Email is required. Please enter your Email.");
      return;
    } else if (!isEmailValid(email)) {
      Notiflix.Notify.failure("Invalid email. Please enter a valid email.");
      return;
    }
    if (dayOfVisit == "") {
      Notiflix.Notify.failure(
        "Day of visiting is required. Please select a day from the dropdown."
      );
      return;
    }
    if (person == "") {
      Notiflix.Notify.failure(
        "Number of persons visiting is required. Please select from the dropdown."
      );
      return;
    }
    if (msg == "") {
      Notiflix.Notify.failure(
        "Please specify the cities you're interested in."
      );
      return;
    }
    handleSubmitForm();
  };

  const handleSubmitForm = () => {
    // Notiflix.Loading.dots("Please Wait");
    const formattedDateRange = formatDateRange(
      filteredItems.length > 0 ? filteredItems[0].fld_Start_Date : startDate,
      filteredItems.length > 0 ? filteredItems[0].fld_End_date : endDate
    );
    // const formattedVisitingDate = formatVisitingDate(dayOfVisit);
    const formattedVisitingDate = moment(dayOfVisit).format('DD MMMM, YYYY')
    PostApiCall.postRequest(
      {
        visitorid: visitorId,
        eventid: eventId,
        visitorname: name,
        email: email,
        mobile: mobile,
        DayofAttending: formattedVisitingDate,
        message: msg,
        taxfiling: taxService,
        indiainvestment: indianInvestments,
        residentials: residential,
        plots: plots,
        villa: villa,
        person: person,
        commercial: commercial,
        eventname:
          filteredItems.length > 0 ? filteredItems[0].fld_location : eventName,
        eventsite:
          filteredItems.length > 0 ? filteredItems[0].fld_venue : siteName,
        date: formattedDateRange,
        eventsiteurl: registrationUrl, // Add the registration URL to the payload
        website: registrationUrl
      },
      "UpdateVisitorRegistration"
    ).then((result) =>
      result.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          Notiflix.Notify.success("Form Submitted Succesfully");
          handleFormReset();
          Notiflix.Loading.remove();
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("Something Went Wrong!");
        }
      })
    );
  };

  return (
    <>
      <div className="border p-3 w-100 visitor-registration-form">
        <div className="main-heading main-heading-left">
          <h2>Kindly Register Below - Free Registration!</h2>
          <p>Snacks & High Tea for all Registered Clients</p>
        </div>
        <div className="row">
          <div className="col-12 mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Name *"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="col-lg-6 mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Mobile Number *"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
            />
          </div>
          <div className="col-lg-6 mb-3">
            <input
              type="email"
              className="form-control"
              placeholder="Email *"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          {showEvents ? (
            <>
              <div className="col-lg-6 mb-3">
                <select
                  class="form-select"
                  onChange={(e) => {
                    setEventId(e.target.value);
                    const eventData = JSON.parse(
                      e.target.options[e.target.selectedIndex].getAttribute(
                        "data-event"
                      )
                    );
                    getDates(eventData.fld_Start_Date, eventData.fld_End_date);
                    setEventName(eventData.fld_location);
                    setSiteName(eventData.fld_venue);
                    setStartDate(eventData.fld_Start_Date);
                    setEndDate(eventData.fld_End_date);

                    setDayOfVisit("");
                  }}
                >
                  <option selected disabled>
                    Select Event *
                  </option>
                  {events.map((event) => (
                    <option
                      value={event.fld_event_id}
                      data-event={JSON.stringify(event)}
                    >
                      {event.fld_location}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-lg-6 mb-3">
                <select
                  className="form-select"
                  onChange={(e) => setDayOfVisit(e.target.value)}
                >
                  <option selected disabled>Day of Visiting *</option>
                  {listedDates.map((value) => (
                    <option value={value}>
                      {moment(value).format("DD-MM-YYYY")}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-lg-12 mb-3">
                <select
                  className="form-select"
                  onChange={(e) => setPerson(e.target.value)}
                >
                  <option selected>Number of Persons Visiting</option>
                  {personList.map((person) => (
                    <option value={person.number}>{person.text}</option>
                  ))}
                </select>
              </div>
            </>
          ) : (
            <>
              <div className="col-lg-6 mb-3">
                <select
                  className="form-select"
                  onChange={(e) => setDayOfVisit(e.target.value)}
                >
                  <option selected>Day of Visiting</option>
                  {listedDates.map((value) => (
                    <option value={value}>
                      {moment(value).format("DD-MM-YYYY")}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-lg-6 mb-3">
                <select
                  className="form-select"
                  onChange={(e) => setPerson(e.target.value)}
                >
                  <option selected>Number of Persons Visiting</option>
                  {personList.map((person) => (
                    <option value={person.number}>{person.text}</option>
                  ))}
                </select>
              </div>
            </>
          )}

          <div className="col-lg-12 mb-3">
            <textarea
              className="form-control"
              placeholder="Kindly mention cities that you are interested in - Bangalore, Chennai, Delhi NCR, Hyderabad, Mumbai, Pune, Ahmedabad, Vadodara, Kochi *"
              rows={5}
              value={msg}
              onChange={(e) => setMsg(e.target.value)}
            />
          </div>

          <b className="mb-2">Add on Services.</b>
          <div className="col-lg-6">
            <div className="mb-3">
              <lable>Please tick if need Assistance for :</lable>
              <div className="form-check mt-2 mb-2 mb-lg-0">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={taxService}
                  onClick={() => setTaxService(!taxService)}
                  checked={taxService == true}
                  id="flexCheckDefault"
                />
                <label className="form-check-label" for="flexCheckDefault">
                  Tax Filing & Will in India
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={indianInvestments}
                  onClick={() => setIndianInvestments(!indianInvestments)}
                  checked={indianInvestments == true}
                  id="flexCheckChecked"
                />
                <label className="form-check-label" for="flexCheckChecked">
                  Indian Investments
                </label>
              </div>
            </div>
          </div>
          <div className="col-lg-6 mb-3 mb-lg-0">
            <lable>Looking for :</lable>
            <div className="d-flex gap-3 mt-2 mb-2 mb-lg-0">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={residential}
                  onClick={() => setResidential(!residential)}
                  checked={residential == true}
                  id="flexCheckDefault"
                />
                <label className="form-check-label" for="flexCheckDefault">
                  Residential
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={plots}
                  checked={plots == true}
                  onClick={() => setPlots(!plots)}
                  id="flexCheckChecked"
                />
                <label className="form-check-label" for="flexCheckChecked">
                  Plots
                </label>
              </div>
            </div>
            <div className="d-flex gap-5 gap-lg-3">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={villa}
                  onClick={() => setVilla(!villa)}
                  checked={villa == true}
                  id="flexCheckChecked"
                />
                <label className="form-check-label" for="flexCheckChecked">
                  Villa
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={commercial == true}
                  onClick={() => setCommercial(!commercial)}
                  checked={commercial == true}
                  id="flexCheckChecked"
                />
                <label className="form-check-label" for="flexCheckChecked">
                  Commercial
                </label>
              </div>
            </div>
          </div>
          <div>
            <button
              className="theme-btn"
              onClick={() => handleValidateForm()}
            >
              Register as a Visitor
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
