import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "./MainHomeSlider.css";
import singapore from "../../assest/img/home-banner/singapore-banner.jpg";
// import banner2 from "../../assest/img/home-banner/2.png";
import jakarta from "../../assest/img/home-banner/setiabudi-jakarat-banner.jpg";
import belgium from "../../assest/img/home-banner/belgium-banner.jpg";
import singaporemobile from "../../assest/img/home-banner/singapore-mobile-banner.jpg";
// import Mobilebanner2 from "../../assest/img/home-banner/mb2.png";
import jakartamobile from "../../assest/img/home-banner/setiabudi-jakarat-mobile-banner.jpg";
import belgiummobile from "../../assest/img/home-banner/belgium-mobile-banner.jpg";
export default function MainHomeSlider() {
  return (
    <Swiper
      autoplay={{
        delay: 2500,
        disableOnInteraction: true,
      }}
      pagination={{
        clickable: true,
      }}
      loop={true}
      draggable={true}
      modules={[Autoplay, Pagination]}
      className="mySwiper home-swiper">
      <SwiperSlide>
        <img src={singapore} className="img-fluid d-none d-lg-block" alt="banner" />
        <img src={singaporemobile} className="img-fluid d-block d-lg-none" alt="banner" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={jakarta} className="img-fluid d-none d-lg-block" alt="banner" />
        <img src={jakartamobile} className="img-fluid d-block d-lg-none" alt="banner" />
      </SwiperSlide>
      <SwiperSlide>
        <img src={belgium} className="img-fluid d-none d-lg-block" alt="banner" />
        <img src={belgiummobile} className="img-fluid d-block d-lg-none" alt="banner" />
      </SwiperSlide>
    </Swiper>
  );
}
