import { Helmet } from "react-helmet";
import Footer from "../Components/Footer/Footer";
import VisitorRegistrationForm from "../Components/Forms/VisitorRegistrationForm";
import WebsiteNavbar from "../Components/WebsiteNavbar/WebsiteNavbar";
import VisitorBanner from "../assest/img/visitor.png";
export default function VisitorRegistrationPage() {
  return (
    <div>
      <Helmet>
        <title>
          Experience the Magic of Indian Real Estate: Register Now!        </title>
        <meta
          name="description"
          content="Discover a world of opportunities in Indian real estate! Register now for our exhibition and explore a wide range of properties tailored to your preferences."
        />
        <meta name="keywords" content="Visitor Registration, NRI Nivesh Property Expo, Real estate, India Property Exhibition" />

        <meta
          property="og:title"
          content="Experience the Magic of Indian Real Estate: Register Now!"
        />
        <meta
          property="og:description"
          content="Discover a world of opportunities in Indian real estate! Register now for our exhibition and explore a wide range of properties tailored to your preferences."
        />
        <meta property="og:url" content=" https://indiapropexpo.com/visitor-registration
" />
        <link rel="canonical" href="https://indiapropexpo.com/visitor-registration" />        <meta property="og:image" content="https://indiapropexpo.com/static/media/visitor.0b6cf8dfb315d72b627b.png" />
        <meta
          name="twitter:title"
          content="Experience the Magic of Indian Real Estate: Register Now!"
        />
        <meta
          name="twitter:description"
          content="Discover a world of opportunities in Indian real estate! Register now for our exhibition and explore a wide range of properties tailored to your preferences.
"
        />
        <meta name="twitter:image" content="https://indiapropexpo.com/static/media/visitor.0b6cf8dfb315d72b627b.png" />
      </Helmet>
      <WebsiteNavbar />
      <div>
        <img src={VisitorBanner} className="img-fluid" alt="Visitor Registration, NRI Nivesh Property Expo, Real estate, India Property Exhibition" />
        {/* <img
          src={VisitorMobileBanner}
          className="img-fluid d-block d-lg-none"
        /> */}
      </div>
      <div className="container-fluid my-4">
        <div className="row">
          <div className="col-lg-6">
            <div className="main-heading main-heading-left">
              <h2>Kindly Register Below - Free Registration!</h2>
              <p>Snacks & High Tea for all Registered Clients</p>
            </div>
            <p className="text-justify">
              Unlock endless possibilities in Indian real estate! Register now
              for our exhibition and explore a diverse range of properties
              personalized to your needs. Simply fill out the form below for
              free admission to secure your spot and embark on your property
              journey with us and enjoy snacks & high tea during the event.
            </p>
            <b>Who can attend our exhibition?</b>
            <ul className="visitor-ul">
              <li>Indians</li>
              <li>Non-Resident Indians (NRIs)</li>
              <li>People of Indian Origin (PIOs)</li>
              <li>Overseas Citizen of India (OCIs)</li>
              <li>Resident Indians living in India or abroad</li>
            </ul>
            <p className="text-justify">
              Please note that only individuals belonging to the categories
              mentioned above are permitted to purchase properties in India.
            </p>
            <p className="text-justify">
              To complete your registration, please fill out the form given.
              Once submitted, you'll be officially registered for the exhibition
              and will receive further details via email.
            </p>
          </div>
          <div className="col-lg-6 visitor-registration-page-form">
            <VisitorRegistrationForm />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
