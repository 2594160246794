import React, { useEffect, useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { BsFileEarmarkPdf } from "react-icons/bs";
import PostApiCall from "../Api/PostApi";
import { Navbar } from "../Navbar/NavBar";
import { Charts } from "./Charts/Charts";
import { DashBoardTable } from "./DashBoardTable/DashBoardTable";
import { TopHeader } from "./TopHeader/TopHeader";
export const Dashboard = () => {
    const [visitorDetails, setVisitorDetails] = useState([]);
    const [stallRegistration, setStallRegistration] = useState([]);
    useEffect(() => {
        PostApiCall.postRequest(
            {
                whereClause: "",
                recordCount: "Top 10 *"
            },
            "GetVisitorDetails"
        ).then((results) =>
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    setVisitorDetails(obj.data);
                }
            })
        );
        PostApiCall.postRequest(
            {
                whereClause: "",
                recordCount: "Top 10 *"
            },
            "GetStallRegistration"
        ).then((results) =>
            results.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    setStallRegistration(obj.data);
                }
            })
        );
    }, [])
    const Visitordata = {
        columns: [
            {
                label: "S No.",
                field: "SNo",
                sort: "asc",
                width: 30,
            },
            {
                label: "Visitor Name",
                field: "VisitorName",
                sort: "asc",
                width: 30,
            },
            {
                label: "Email & Mobile",
                field: "Emailandmobile",
                sort: "asc",
                width: 50,
            },
            {
                label: "Day of Attending",
                field: "DayOfAttending",
                sort: "asc",
                width: 350,
            },
            {
                label: "Looking for",
                field: "Lookingfor",
                sort: "asc",
                width: 350,
            },
            {
                label: "Need Assistance For",
                field: "needAssistancefor",
                sort: "asc",
                width: 350,
            },
            {
                label: "Message",
                field: "Message",
                sort: "asc",
                width: 150,
            },
            {
                label: "Location",
                field: "Location",
                sort: "asc",
                width: 100,
            }
        ],
        rows:
            visitorDetails?.map((data, i) => {
                return (
                    {
                        SNo: i + 1,
                        VisitorName: data.fld_visitor_name,
                        Emailandmobile: <>
                            <div>{data.fld_email}</div>
                            <div>{data.fld_Mobile}</div>
                        </>,
                        DayOfAttending: data.Day_of_attending,
                        Message: data.fld_message,
                        Location: data.fld_location,
                        Lookingfor: <>
                            {data.fld_residentials == "true" && <p>{data.fld_residentials == "true" ? "Residential" : ""}</p>}
                            {data.fld_plots == "true" && <p>{data.fld_plots == "true" ? "Plots" : ""}</p>}
                            {data.fld_villa == "true" && <p>{data.fld_villa == "true" ? "Villa" : ""}</p>}
                            {data.fld_commercial == "true" && <p>{data.fld_commercial == "true" ? "Commercial" : ""}</p>}
                        </>,
                        needAssistancefor:
                            <>
                                {data.fld_india_investment == "true" && <p>{data.fld_india_investment == "true" ? "Indian Investments" : ""}</p>}
                                {data.fld_taxfiling == "true" && <p>{data.fld_taxfiling == "true" ? "Tax Filing & Will in India" : ""}</p>}
                            </>,
                        action: [<AiOutlineEye />, <BsFileEarmarkPdf />],
                    }
                )
            })
        ,
    };
    const BookingEnquiry = {
        columns: [
            {
                label: "S No.",
                field: "SNo",
                sort: "asc",
                width: 30,
            },
            {
                label: "Company Name",
                field: "companyName",
                sort: "asc",
                width: 130,
            },
            {
                label: "Contact Person",
                field: "contactPerson",
                sort: "asc",
                width: 50,
            },
            {
                label: "Email & Mobile",
                field: "Emailandmobile",
                sort: "asc",
                width: 50,
            },
            {
                label: "Message",
                field: "Message",
                sort: "asc",
                width: 150,
            },
            {
                label: "Customer's Location",
                field: "Location",
                sort: "asc",
                width: 100,
            }
        ],
        rows:
            stallRegistration?.map((data, i) => {
                return (
                    {
                        SNo: i + 1,
                        companyName: data.fld_company_name,
                        contactPerson: data.fld_name,
                        Emailandmobile: <>
                            <div>{data.fld_email}</div>
                            <div>{data.fld_mobile}</div>
                        </>,
                        Message: data.fld_message,
                        Location: data.fld_location,
                    }
                )
            })
        ,
    };
    return (
        <div className="main-dashboard">
            {/* <Navbar /> */}
            <Navbar />
            <TopHeader />
            <Charts />
            <DashBoardTable type="Visitor Registration List" data={Visitordata} />
            <DashBoardTable type="Booking Enquiry" data={BookingEnquiry} />
            {/* <CalendarSection /> */}
            {/* <BottomTable /> */}
            {/* <Footer /> */}
        </div>
    );
};
